export default {
  "/": {
    title: "AppInChina: Unlock the Full Potential of China's App Market",
    description:
      "Effortless Launch of Your App, Game, or Platformr in China. We have helped some of the world's most successful companies publish their apps and games in China.",
    featuredImage: "/images/featured/localization.jpg",
  },
  "/services/localization/": {
    title: "Expert App Localization for Distribution in China",
    description: "Ensure legal compliance and market entry success in China with our expert localization services. We handle copyright registration, licensing, and Mandarin translation seamlessly.",
    featuredImage: "",
  },
  "/services/monetization/": {
    title: "Monetization Services for Apps and Games in China",
    description: "Simplify in-app payments in China with AppInChina Pay. Integrate WeChat Pay and Alipay seamlessly. Whether ad-supported or direct payments, we've got you covered for hassle-free monetization.",
    featuredImage: "/images/featured/monetization.jpg",
  },
  "/services/integration/": {
    title: "App Integration in China",
    description: "We optimize your app or game to function flawlessly within mainland China, identifying and suggesting alternatives for blocked Google Services and other dependencies. Trust us to test relentlessly, ensuring your app reaches all users with no disruptions.",
    featuredImage: "/images/featured/integration.jpg",
  },
  "/services/hosting/": {
    title: "Hosting Serivces in China. Secure and compliant hosting for apps and games.",
    description: "AppInChina's China-side hosting solutions secures your app data on servers physically on Chinese soil, providing an easy way to comply with local laws.",
    featuredImage: "/images/featured/hosting.jpg",
  },
  "/services/game-publishing/": {
    title: "Mobile Game Publishing in China",
    description: "Half of China plays mobile games. We will get them playing yours by taking care of every step - from distribution to promotion to monetization.",
    featuredImage: "/images/featured/game-publishing.jpg",
  },
  "/services/distribution/": {
    title: "App and Game Distribution in China",
    description: "We publish and maintain your iOS and Android apps on China's Apple App Store and top Android app stores, so you can get a piece of this $37+ billion market.",
    featuredImage: "/images/featured/distribution.jpg",
  },
  "/services/compliance/": {
    title: "App and Game Compliance in China",
    description: "We make sure your business remains compliant with Chinese law with the required licenses, certifications, copyright certificates and other documents for your app or game.",
    featuredImage: "/images/featured/user_acquisition.jpg",
  },
  "/services/acquisition/": {
    title: "User Acquisition for Apps and Games in China",
    description: "Once your app or game is published in China, we will help promote it through the app stores, social media, CPD campaigns, organic search, and more.",
    featuredImage: "/images/featured/user_acquisition.jpg",
  },
  "/services/other/": {
    title: "AppInChina | Other Services",
    description: "In addition to our core offerings, we provide all the other services you need to expand your brand and run a successful web-based or app-based business in China.",
    featuredImage: "/images/featured/other_services.jpg",
  },
  "/games/game-licenses/": {
    title: "China Game License Database | AppInChina",
    description: "Searchable database of games license approvals in China, compiled from official Chinese goverment data and translated into English for easy reference.",
    featuredImage: "/images/featured/game-publishing-2.jpg",
  },
  "/blog/": {
    title: "Blog | AppInChina",
    description: "The information center for all things Android app related in China. Covering the latest local market trends and sharing the most effective ways to gain success in the Chinese marketplace.",
    featuredImage: "/images/featured/blog.jpg",
  },
  "/get-started/": {
    title: "Get Started | AppInChina",
    description: "Have a project we can help you with? Contact us now for a free consultation or to request a quote. Simply fill out the form below, and we’ll contact you within 2 working days.",
    featuredImage: "",
  },
  "/government-policies-china/": {
    title: "Official Chinese Government Policies | AppInChina",
    description: "Here you will find English translations of official Chinese government laws, policies, and press releases related to mobile technology - particularly mobile apps and mobile games in China. Each includes a link to the original post on the web.",
    featuredImage: "",
  },
  "/infographics/": {
    title: "Infographics | AppInChina",
    description: "The latest statistics, facts and figures about the Chinese app and game market, presented in easy-to-read downloadable infographics and charts for your use.",
    featuredImage: "/images/featured/infographics.jpg",
  },
  "404": {
    title: "AppInChina",
    description: "Page not found.",
    featuredImage: "",
  },
  "/media/": {
    title: "Media | AppInChina",
    description: "News about AppInChina on the internet.",
    featuredImage: "/images/featured/media.jpg",
  },
  "/our-company/": {
    title: "Our Company | AppInChina",
    description: "AppInChina is the market-leading platform used by companies to publish their apps to the 1.1 billion active Android devices not covered by Google Play.",
    featuredImage: "",
  },
  "/payments/": {
    title: "Payments Infrastructure For China | AppInChina",
    description: "Use AppInChina Pay to accept payments in Mainland China, and manage your China-side business online from anywhere in the world.",
    featuredImage: "",
  },
  "/success/": {
    title: "Success Stories | AppInChina",
    description: "Both established companies and individual app developers have successfully published their innovative apps in China thanks to AppInChina.",
    featuredImage: "",
  },
  "/market/app-stores/": {
    title: "The AppInChina App Store Index | The Market",
    description: "The AppInChina App Store Index is the market-leading index of China’s largest Android app stores. We update our index on a quarterly basis using the most accurate data sources available.",
    featuredImage: "",
  },
  "/market/cloud-provider/": {
    title: "The AppInChina Cloud Provider Index | The Market",
    description: "The AppInChina Cloud Provider Index is the market-leading index of China's major cloud providers. We update our index on a quarterly basis using the most accurate data sources available.",
    featuredImage: "",
  },
  "/market/apps/": {
    title: "The AppInChina App Index | The Market",
    description: "The AppInChina App Index is a ranking of the best-performing Android apps in the Chinese market based on number of monthly active users.",
    featuredImage: "",
  },
  "/market/wechat-mp/": {
    title: "The AppInChina Wechat Mini Program Index | The Market",
    description: "The AppInChina WeChat Mini Program index ranks the top 20 WeChat Mini Programs based on their monthly average users.",
    featuredImage: "",
  },
  "/market/device-brands/": {
    title: "The AppInChina Mobile Device Index | The Market",
    description: "This index shows the most popular brands of mobile device as a percentage of total active mobile devices in China.",
    featuredImage: "",
  },
  "/market/game-stores/": {
    title: "The AppInChina Game Store Index | The Market",
    description: "The AppInChina Game Store Index is the market-leading index of China’s largest Android app stores that publish games. We update our index on a quarterly basis using the most accurate data sources available.",
    featuredImage: "",
  },
  "/market/games/": {
    title: "The AppInChina Mobile Game Index | The Market",
    description: "The AppInChina Mobile Game Index tracks the top 20 mobile games in China across all platforms (iOS and Android).",
    featuredImage: "",
  },
  "/test-your-site-in-china/": {
    title: "Test Your Site in China | AppInChina",
    description: "Many of the world’s websites are blocked behind the “Great Firewall of China”. Is your website available to Chinese users? Enter your URL to test your site’s availability in Mainland China and find out.",
    featuredImage: "",
  },
  "/search/": {
    title: "Search Page | AppInChina",
    description: "The AppInchina Search Page",
  },
};
